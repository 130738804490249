.draft-actions button {
    margin: 4px;
}

.draft-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5vh;
}

.bottom-action-buttons button {
    margin-top: 4px;
    width: 100%;
    height: 100%;
}