.spinner-grow {
    animation: custom-grow 4.0s linear infinite;
    border-radius: 0;
    opacity: 1;
    margin: 1px 2px 1px 2px;
    padding: 0;
}

@keyframes custom-grow {
    0% {
        transform: scale(0.00);
    }

    50% {
        transform: scale(1.00);
    }

    75% {
        transform: scale(1.00);
    }

    100% {
        transform: scale(1.00);
    }
}

.spinner-grow.text-danger {
    animation-delay: 0.00s;
}

.spinner-grow.text-success {
    animation-delay: 0.50s;
}

.spinner-grow.text-warning {
    animation-delay: 1.00s;
}

.spinner-grow.text-primary {
    animation-delay: 1.50s;
}
