body {
    overflow-x: hidden;
}

.btn-primary {
    background-color: #006FE6!important;
}

.btn-outline-primary {
    color: #006ee5;
    border-color: #006ee5;
}

.btn-outline-secondary {
    border-color: #5F666D!important;
    color: #5F666D!important;
}

.bg-success, .btn-success {
    background-color: #1E7B34!important;
}

.btn-info {
    background-color: #117888!important;
}

.btn-warning {
    background-color: #FFBF00!important;
}

.btn-danger {
    background-color: #D92638!important;
}

a {
    color: #006FE6!important;
}

.form-control:focus, .btn:focus {
    outline: none !important;
    box-shadow: 0 0 10px 2px black, 0 0 2px 2px black;
}

.table:focus {
    border: 4px solid;
}

.icon-warning {
    color: #FFBF00!important;
}
