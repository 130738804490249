div.setting-tree-name-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

div.setting-tree-name-buttons {
    display: inline-block;
    padding-left: 8px;
    cursor: pointer;
    /* The height/width has to be at least 24px for accessibility */
    min-height: 24px;
    min-width: 24px;
}

div.selected {
    background: #8A8A8A;
}

div.setting-tree-name-text {
    cursor: pointer;
}