.test {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
/*@media(max-width: 768px) {
    .test {
        display: flex;
        flex-direction: row;
        max-width: 100%;
    }
}*/
.column-div {
    
    max-width: 160px;
}

.submission-button {
    margin-top: 8px;
}