.simple-modal-show-button {
    width: 100%;
}

.simple-modal-body {
    min-height: 25vh;
}

.modal {
    z-index: 1040;
}

.simple-modal-tooltip {
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 100%;
}

.simple-modal-title-icon {
    display: flex;
    align-items: center;
    padding-right: 10px;
    height: 100%;
}