.tabbed-container-tab {
    margin: 4px 8px 4px 0;
    vertical-align: top;
}

.tabbed-container-tab-main {
    min-width: calc(2.5em + 1.75rem + 4px);
}

.tabbed-container-input {
    display: inline-block;
    margin: 4px 8px 4px 0;
    vertical-align: top;
    max-width: calc(6em + 4rem + 8px);
}
