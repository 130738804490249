.collapsable-container-header {
    line-height: calc(1.25em + 0.75rem + 4px);
}

.collapsable-container-chevron {
    min-width: calc(0.5em + 0.25rem + 4px);
}

.collapsable-container-label {
    max-width: 100%!important;
}

.expandable {
    cursor: pointer;
}
