.mps-validation-icon-warning {
    color: gold;
}

.mps-validation-icon-danger {
    color: #D92638 !important;
}

.mps-validation-icon-success {
    color: #1E7B34 !important;
}

#validationResults {
    color: black;
    font-family: monospace;
}