.description-tool-tip {
    max-width: 300px;
}

.description-tooltip-container {
    min-width: 24px;
    min-height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}