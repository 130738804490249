.toast-queue-container {
    pointer-events: none;
    top: -58px;
    width: 98%;
}

.toast-queue-stack {
    pointer-events: all;
    min-width: calc(10em + 5rem + 50px);
    z-index: 99999 !important;
}

.close {
    opacity: 1;
}

.toast-close-white .close {
    color: white;
}

.toast-close-black .close {
    color: black;
}

.toast-queue-stack .toast-body {
    background-color: white;
    border: none;
    white-space: pre-wrap;
}
