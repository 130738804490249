.form-label {
    font-weight: 500;
    max-width: 80%;
    margin-bottom: 0;
    vertical-align: middle !important;
    white-space: nowrap;
    text-wrap: wrap;
}

.form-label-tooltip-icon {
    display: inline-block;
    vertical-align: middle !important;
}

.form-label-icon {
    vertical-align: middle !important;
}

.tooltip-text {
    white-space: pre-wrap !important;
    line-height: 1.5 !important;
    max-width: 300px;
}
