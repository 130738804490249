div.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: right;
    background: white;
}

ul.footer-ul { 
    display: inline;
}

li.footer-item {
    display: inline;
    padding-right: 15px;
}