.draft-submission-col {
    max-width: 160px;
}

.padding-bottom {
    /* 
     * This is a hack to make sure that the footer doesn't display over the content.
     * This could be cleaner but this is the only area that has this issue.
    */
    padding-bottom: 48px;
}

@media(max-width: 992px) {
    .draft-submission-col {
        max-width: 100%;
    }
}