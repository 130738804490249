/*div.navigation-container {
    background: silver;
    height: inherit;
    display: flex;
    flex-direction: column;
    padding: 0px;
}*/

div.edit-profile-container {
    max-width: 100%
}

/* If we don't use inherit height, the scroll functionality doesn't work right*/
.inherit-height {
    height: inherit;
}

.edit-settings-container {
    height: inherit;
    overflow-y: auto;
    border: black;
    border-style: solid;
}