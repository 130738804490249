div.profile {
    background: white;
    margin: 0px 4px 8px 4px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.pointer-selectable {
    cursor: pointer;
}

div p {
    margin: 4px
}