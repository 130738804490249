div.profile-name-header {
    display: flex;
    align-items: center;
    cursor: pointer;
}

div.profile-name-header-profile {
    width: 100%;
}

div.setting-tree-navigation-container {
    margin: 8px;
    height: inherit;
    display: flex;
    flex-flow: column;
    color: black;
}