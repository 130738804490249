.new-profile-button {
    margin-bottom: 8px
}
div.profile-list-navigation {
    display: flex;
    flex-direction: column;
    height: inherit;
    align-items: center;
}

div.profile-filter-container {
    display:flex;
    justify-content: space-between;
    margin-top: 8px;
    padding: 0px 8px 8px 8px;
    width: 100%;
    align-items: center;
}

.profile-filter {
    margin: 0px;
    width: 100%;
}

.profile-search-icon {
    margin-left: 8px;
}