.selected-row {
    background-color: #949494;
}

.caret-icon {
    margin-left: 4px;
}

tr.pointer-selectable {
    cursor: pointer;
}

tr.pointer-selectable:hover {
    background-color: #949494 !important;
}

/* Method to visually hide something but still */
/* make it available to screen readers */
.visually-hidden {
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;
}