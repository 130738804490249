.multi-select-tag {
    margin-right: 4px;
    margin-bottom: 8px;
}

.multi-select-tag-body {
    min-width: calc(1.75em + 1rem + 4px);
}

button:disabled {
    cursor: default;
}
