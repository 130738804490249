div.navigation-container {
    background: #F0F0F0;
    padding-top: 2px;
    height: inherit;
    display: flex;
    flex-direction: column;
    padding: 0px;
}

div.display-container {
    height: inherit;
    overflow-y: auto;
    border: black;
    border-style: solid;
}

div.navigation-row {
    height: 80vh;
    overflow-y: hidden;
}

@media (max-width: 767px) {
    div.navigation-row {
        height: auto;
    }
}