.exp-list-row {
    margin-bottom: 12px;
}

button.exp-list-remove-button {
    display: inline-block;
    height: 100%;
}

.exp-list-remove-button-col {
    margin: 0;
    padding: 0;
}

button.exp-list-add-button {
    margin-bottom: 12px;
    width: 100%;
}
